<template>
    <figure class="image">
        <img class="quest-gif" :src="questGif">
    </figure>
</template>

<style lang="scss">
    .quest-gif{
        height: 300px !important;
        width: auto !important;
    }
</style>


<script>
    import questGif from './quest-crop.gif';
    export default {
        name: 'ob-ghost',
        data(){
            return {
                questGif
            }
        }
    }
</script>
