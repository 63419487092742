export const messages = {
    en: {
        global: {
            openbook: '<span style="color: #4a4a4a;"><strong>Nile</strong>  Basket</span>'
        },
        home: {
            want_to_know_more: 'Want to know more?',
            read_manifesto: 'Read our manifesto',
            featured_in: 'As featured in'
        },
        honesty: {
            title: 'Empowerment',
            description: 'Nile Basket empowers farmers, producers, retailors reach wider consumers and allows shopper express their values through everyday shopping.  Consumers are increasingly demanding more information where and how products are produced we enables sourcing products made by African within African content to global audience.'
        },
        privacy: {
            title: 'Why Nile Basket',
            description: '<span class="has-text-circular"><strong>Nile</strong> Basket</span> helps African organizations to provide better customer service. Nile Basket helps to simplify the business processes and makes them faster and efficient.'
        },
        secure: {
            title: 'Higher Margin to developers',
            description: 'Minimize the middle person, our direct sourcing model where possible minimize loss of revenue and transfer the higher margins directly to the producers.'
        },
        easy: {
            title: 'Family Driven',
            description: 'With four devoted founders and many supporters, Nile basket is here to stay for long endeavors!'
        },
        fun: {
            title: 'More ways to express yourself',
            description: 'We humans are emotional beings. Find more ways to express your digital self with our increasing reactions library!'
        },
        personal: {
            title: 'Fair Market Place',
            description: 'Every purchase supports something or someone, We at <span class="has-text-circular"><strong>Nile</strong> Basket</span> exist so we can support African continent.'
        },
        community: {
            title: 'Community driven',
            description: 'With <strong>1900+</strong> backers and volunteers, <span class="has-text-circular"><strong>Open</strong>book</span> is more than a social network, <strong>it\'s a movement</strong>.'
        },
        good_for_planet: {
            title: 'Good for the planet',
            description: 'We believe in a better world for everyone. That\'s why we\'ll be giving 30% of our profits towards making the world a better place, in partnership with <a href="https://founderspledge.com/" rel="noreferrer noopener">FoundersPledge</a>.'
        },
        ads_free: {
            title: 'Better Africa',
            description: 'We believe in a fair trade and stronger Africa.  That is why we have developed Nile Basket to market quality African product to global community.  We strive to support that mission one product at a time, and enable long-term sustainment by giving back a percentage of the profit to educate and foster economical mobilization within continent.'
        },
        currency: {
            title: 'A digital currency',
            description: 'We\'re also working on a digital currency called <strong>Tip</strong> to be used as a way to support your favourite video-maker, subscribe to publishers, pay for services and more.'
        },
        quotes: {
            joel_name: 'Joel Hernández',
            joel_title: 'Founder of Openbook',
            joel_quote: 'We\'re at a breaking point. We need to stop building products for users and start building products for people. To put humanity at the core of everything we build.',
            vincent_name: 'Vincent Ruijter',
            vincent_title: 'Ethical Hacker',
            vincent_quote: 'Openbook might be the last real chance to build something different, something ethical, something better.',
            phil_name: 'Phil Zimmermann',
            phil_title: 'Internet hall of fame member',
            phil_quote: 'Openbook holds hope for the future, a social network where we are not victims of its revenue model.'
        },
        splash_hero: {
            hello_world: 'Hello <span id="hello-subject">world!</span>',
            world: 'world',
            friends: 'Africa',
            family: 'family',
            grandma: 'friends',
            reddit: 'grandma',
            we_are_ob: 'We\'re <strong class="has-text-circular">Nile</strong> Basket.',
            kickstarter: 'A marketplace for a <strong>better tomorrow.</strong>',
            share: 'Share',
            notify_me: 'Support us'
        },
        why_ob: {
            title: 'Why Openbook?',
            subtitle: 'Some of the key things that set us apart from other social networks.',
            zero_tracking: 'Zero tracking',
            zero_tracking_desc: 'We will never track you, anywhere, anyhow.',
            zero_spying: 'Zero spying',
            zero_spying_desc: 'Be confident your private conversations are truly private.',
            zero_ads: 'Zero advertisement',
            zero_ads_desc: 'Advertisement lead to targeted advertisement, which in turn lead to the loss of online privacy. We won\'t have ads.',
            data_protection: 'Tough data protection',
            data_protection_desc: 'Third party applications that wish to access your data will have to comply with our strict and tough security policies.',
            honest: 'Honest',
            honest_desc: 'All of our code is free and open for everyone to inspect, reproduce and contribute to. No unpleasant surprises.',
            easy_to_migrate: 'Easy to migrate',
            easy_to_migrate_desc: 'We will provide tools to migrate all of your existing data to our network.',
            future_proof: 'Future-proof',
            future_proof_desc: 'With a solid and useful revenue model based on peer-to-peer transactions, we are in for the long run.',
            modern_design: 'Modern, beautiful, ethically designed.',
            modern_design_desc: 'Built with the latest technologies, a keen eye for detail and ethics, you won\'t find anything like it elsewhere.',
            good_for_planet: 'Good for the planet',
            good_for_planet_desc: 'We believe in a better world for everyone. That\'s why we\'re giving 30% of our profits towards making the world a better place, now and\n' +
                '                                    forever.'
        },
        count_down: {
            kickstarter: 'Openbook',
            campaign_countdown: 'launch countdown',
            days: 'day | days',
            hours: 'hour | hours',
            minutes: 'minute | minutes',
            seconds: 'second | seconds',
            kickstarter_launch: 'Openbook <strong class="has-text-kickstarter">alpha</strong> ' +
                'begins <br/>on the<strong> {0}th of March 2019</strong>.'
        },
        meet_the_team: {
            title: 'Meet the team'
        },
        header: {
            home: 'Home',
            about_us: 'About us',
            manifesto: 'Manifesto',
            contact_us: 'Contact us',
            jobs: 'Vendors',
            faq: 'FAQ',
            press: 'Press',
            language: 'Language',
            blog: 'Blog'
        },
        footer: {
            // report_vulnerability: 'Report Vulnerability',
            twitter: 'Twitter',
            github: 'Github',
            reddit: 'Reddit',
            gitter: 'Gitter',
            linked_in: 'LinkedIn',
            product_hunt: 'Product Hunt',
            facebook: 'Facebook',
            instagram: 'Instagram',
            the_ob_organization: 'Nile Basket',
            privacy_policy: 'Privacy Policy',
            made_with: 'Made with ',
            // in_europe: ' in Europe'
        },
        hud: {
            share: 'Share',
            share_fb: 'Share on Facebook',
            share_twitter: 'Share on Twitter',
            share_email: 'Send e-mail',
            share_linkedin: 'Share on LinkedIn',
            share_wa: 'Share on Whatsapp',
            share_title: 'Nile Basket. A marketplace for a better future.',
            share_description: 'Honest, personal, empowering and ethically sustained.',
            share_quote: 'Join the revolution! Coming summer 2019.',
        },
        about_us: {
            title: 'About us',
            subtitle: 'Technology for a <span class="has-text-primary">better tomorrow</span>.',
           
            // Eddited by Alie
            description: '<strong>We\'re on a mission to build a platform that is sustainable with fair trade for a more prosperous Africa..</strong>',
            description_founded: 'We bring our mission to life by enabling African product made in Africa to western economies, which would enable Africans merchants, manufacturers reach western economies through eCommerce.  Ultimately empowering African suppliers to flourish and create higher revenue and employments within Africa.',
            description_project: '<span class="has-text-circular"><strong>Nile Basket</strong></span>  is our first creation.',

            description_funded: 'The project was successfully crowd-funded September 2018 through a <a href="https://www.kickstarter.com/projects/1520156881/openbook-privacy-friendly-fun-and-honest-social-ne" rel="noopener noreferrer">Kickstarter Campaign</a>.',

            description_release: '<span class="has-text-circular"><strong>Nile Basket</strong></span> is currently in closed beta testing with trusted users and will be available early 2020.',

            accomplish_how: 'Why a marketplace?',
            read_our_manifesto: 'Read our manifesto',
            humans_behind_ob: 'The people',
            work_with_us: 'Want to partner with us?',
            visit_jobs_page: 'Visit our vendor page',
            our_ambassadors: ' Our ambassadors',
            ambassadors_desc: 'People that help us spread the word around and stand for everything we do.',
            become_ambassadors: 'Want to become an ambassador?',
            contact_us: 'Contact us'
        },
        team_members: {
            joel_title: 'Partner',
            joel_desc: 'Privacy and security activist, software engineer, digital products designer, open sourcerer, The Next Web Top 500 young talent, human.',
            jaya_title: 'Partner',
            jaya_desc: 'Globally recognized information-security expert, speaker on lawful interception, quantum-computing, counter surveillance and cryptography.',
            phil_title: 'Partner',
            phil_desc: 'Cryptography pioneer, creator of PGP, the most widely used email encryption software in the world, internet hall of fame member, privacy activist.',
            lambrecht_title: 'Partner',
            lambrecht_desc: 'Security and international relations expert, conflict analyst, peace-builder, media researcher & producer, human rights activist.',
            vincent_title: 'Hacker-in-residence',
            vincent_desc: 'Ethical hacker, reverse-engineer, exploit developer, speaker, does not like the word cyber, cyber expert.',
            bas_title: 'Senior Concept Exorcist',
            bas_desc: 'Advocate of useful and meaningful products, user experience wizard, worked with numerous international brands, researcher, hardware hacker.',
            shantanu_title: 'Digital Sorcerer',
            shantanu_desc: 'Pragmatic software engineer with a knack for building great products and experiences, crypto-geek, bot-maker, simplicity advocate.',
            nathalie_title: 'Master Catalyst',
            nathalie_desc: 'Master planner, personification of optimism, want to get things done? Call Nathalie. ',
            robin_title: 'Brand Warrior',
            robin_desc: 'Content marketing magician, The Next Web Top 500 young talent, beach lover, hates being disrupted by routine, loves life outside the comfort zone.',
            martijn_title: 'Software Ninjaneer',
            martijn_desc: 'Practical software engineer, JavaScript guru, wearable tech geek, gamer, coolness officer in charge.'
        },
        ambassadors: {
            isaac_name: 'Isaac Hernández',
            isaac_desc: 'Artist, social entrepreneur.',
           
            //Momo Reference
            ruben_name: 'Momo Akwara',
            ruben_desc: 'CEO Transatlantic Trade Expo.',
           
            edwin_name: 'Edwin van Andel',
            edwin_desc: 'CEO at Zerocopter, international speaker, experienced security expert.',
            chris_name: 'Chris Duke',
            chris_desc: 'Host & Executive Producer, Motorz TV'
        },
        contact_us: {
            title: 'Contact us',
            subtitle: 'We want to hear from you.',
            info: 'Contact information',
            message: 'Send us a message'
        },
        address: {
            name: 'Name',
            ob_organization: 'Nile Basket LLC',
            address: 'Address',
            ob_address: 'PO Box 322, 8409 Lee HWY Merrifield, VA 22116-9998',
            telephone: 'Telephone',
            telephone_no: '+1 (443) 815-1567',
            email: 'Email',
            website: 'Website'
        },
        message: {
            subject: 'Subject',
            reason_placeholder: 'The reason you are contacting us',
            email_placeholder: 'The email we can reach you at',
            email: 'Email',
            user_message: 'Message',
            user_message_placeholder: 'What can we do for you?',
            google_recaptcha_title: 'We use <a href="https://www.google.com/recaptcha/intro/v3beta.html" target="_blank"\n' +
                '                                              rel="noopener noreferrer">Google Recaptcha</a>\n' +
                '                                    to prevent the abuse of this form.',
            recaptcha_info: 'Google collects information about you in order to "verify" you are human.',
            info_includes: 'This information includes',
            info_ip_location: 'Your IP, which can be used for determining your location and cross-referencing your identity with other sites you have visited.',
            info_cookies: 'All cookies placed by Google over the last 6 months.',
            info_mouse_clicks: 'How many mouse clicks you’ve made (or touches if on a touch device).',
            info_css: 'The CSS information of the page',
            info_date: 'The date',
            info_browser_lang: 'The language of your browser',
            info_plugins: 'Any plugins you have installed on the browser',
            info_js: 'The Javascript objects on the page',
            recaptcha_authorization: 'Do you authorize us to load Google Recaptcha and therefore allow Google to collect this information?',
            recaptcha_authorization_yes: 'Yes, I authorize you to load Google Recaptcha',
            or: 'or',
            recaptcha_authorization_no: 'No, send an e-mail to info@open-book.org',
            tick_box: 'Please tick this box',
            clear_form: 'Clear form',
            submit: 'Submit'
        },
        jobs: {
            title: 'Vendors',
            subtitle: 'Partner with us.',
            exciting_adventure: 'We have embarked on one of the most exciting adventures for the digital world and we\'re looking for talented and passionate business folks to sail with us!',

            // Taken from YamiBuy's homepage of category showcase
            all_skills: 'Technology, food, beauty, health and baby, home, you name it.',

            need_your_help: 'Do you believe in <span class="has-text-primary"><strong class="has-text-primary">Nile</strong> Basket</span>, a better future for Africa and humanity as a whole?',
            send_cv: 'Please complete the form below.',

            // send_cv: 'Send us your CV and a motivation letter to  <a\n' +
            //     '                        href="mailto:jobs@open-book.org?subject=Job%20Application">jobs@open-book.org</a>.',

            look_forward: 'We look forward to working with you !',
            colleagues: 'Who will you be working with?',
            meet_the_team: 'Meet the team'
        },
        faq: {
            title: 'FAQ',
            subtitle: 'The frequently asked questions.',
            office_dog: 'Our office dog hard at work.',
            jump_to: 'Coming Soon!',
            whos_paying: 'Who\'s paying for everything so far?',
            is_distributed: 'Will Openbook be decentralised?',
            whos_paying_ans: 'So far we have been completely self funded. However, this has proven to be very hard.\n' +
                '                        With most of us working full-time jobs apart from Openbook, it could take us long time till we release the first version and even then, we would not be able to afford the IT infrastructure needed to compete with any of the established networks.',
            crowd_funding: 'As we wanted the project to be driven by people and not capital, we decided to go for crowd-funding.',
            launching_kickstarter: 'We got successfully funded on the 7th of September 2018 at 18:34!',
            why_not_non_profit: 'Why is Openbook not a non-profit?',
            why_not_non_profit_ans: 'Making Openbook a for-profit was a hard choice to make.',
            check_all_boxes: 'We check all the non-profits boxes and we love non-profits. We love Wikipedia, the Ghost Foundation, Founders Pledge, Mozilla and many more.',
            same_struggle: 'However we see the same struggle repeated over and over again. These companies struggle to grow to the size and resources needed to compete with for-profit businesses.',
            need_money: 'When we need to grow, we need to be able to to raise the money needed to do so.',
            hence_for_profit: 'Therefore we are officially a for-profit company.',
            humanitarian: 'But do note that when we do this, we will make sure the people investing in the company will be people with real interest on the platform, its core values of privacy, security, freedom, openness and its humanitarian nature.',
            want_to_help: 'I want to help right now, what can I do?',
            want_to_help_ans: 'We\'re happy and grateful you want to help out!',
            many_ways: 'There are many ways you can help right now.',
            ideas: 'Here are some ideas:',
            share_website: 'Share the website on social media',
            donate: 'Donate in IndieGogo!',
            talk_to_friends: 'Talk to your friends and family about us',
            improve_website: 'If you\'re a developer, you can help us <a\n' +
                '                            href="https://github.com/OpenbookOrg"\n' +
                '                            target="_blank" rel="noopener noreferrer">improve our website</a>.',
            let_us_know: 'If you have an idea for the network, you can <a href="/en/contact-us"\n' +
                '                                                                            target="_blank" rel="noopener noreferrer">let us know</a>.',
            public_figure: 'If you\'re a public figure and would like to become an ambassador,',
            contact_us: 'contact us',
            looking_for_job: 'If you\'re looking for a job and believe Openbook would be a great fit for you,',
            visit_jobs: 'visit our jobs page',
            volunteer: 'And lastly, if you would like to be even more involved, we\'re forming a network of volunteers across the world to help us spread the word.',
            interested: 'Interested?',
            contact_us_2: 'Contact us',
            more_questions: 'Have another question?',
            send_us_message: 'Send us a message',
            why_not_decentralised: 'Will Openbook be decentralised?',
            why_not_decentralised_ans: '<p>We want to get there eventually. We’re looking into <a href="https://solid.mit.edu/" target="_blank" rel="noopener noreferrer">Solid MIT approach</a> (The one from Tim Berners Lee) and we’re very pleased with it so far. </p> <p>Our first versions will however be centralised. </p> <p>We do this because it’s then easier to focus on innovating in the product features and overall user experience. These things will determine whether we’ll reach the user base necessary to take on existing social networks. </p> <p>If we succeed at this, sky’s the limit into what we can do in regards to decentralisation!</p>'
        },
        press: {
            we: 'We',
            press: 'Press',
            today_everyday: 'Today, and everyday.',
            hi: 'Hi there!',
            any_questions: 'We hope you find everything you need below.\n' +
                '                        ​​If you have any questions, would like to schedule an interview or require extra material, feel free to contact Lambrecht Wessels at <a\n' +
                '                        href="mailto:lambrecht@open-book.org?subject=Press%20inquiry&body=">lambrecht@open-book.org</a>.',
            happy_to_help: 'We’re happy to help.',
            jump_to: 'Jump to',
            press_kit: 'Press kit',
            newsroom: 'Newsroom',
            people: 'People',
            logos: 'Logos',
            includes_everything: 'Includes press releases, manifesto, bios, photos, vector logos, misc images and website photos.',
            home_press: 'Home to our press releases.',
            go_to_newsroom: 'Go to newsroom',
            missing_something: 'Missing something? Contact <a\n' +
                '                        href="mailto:lambrecht@open-book.org?subject=Press%20inquiry&body=">Lambrecht Wessels</a>.'
        },
        manifesto: {
            Manifesto: "Manifesto",
            The_proble: "The problem, the solution, the next steps.",
            Reading_ti: "Reading time",
            Table_of_c: "Table of contents",
            Why__: "Why?",
            How__: "How?",
            _Honest: "1. Honesty.",
            _Persona: "2. Diaspora.",
            _Privacy: "3. Privacy-friendly and secure",
            Explicit_a: "Explicit and informed consent",
            Safe_Appli: "Safe Applications",
            Privacy_by: "Privacy by default",
            _Easy: "4. Easy-peasy.",
            _Fun: "3. Research.",
            _Our_Rev: "6. Clean revenue.",
            The_reason: "The reason we're building yet another eCommerce site.",
            Social_net: "eCommerce market is efficient and effective.  It enables the single merchant/supplier reach wider audience.  It connect African supplier, merchant with western buyer, making possible to higher margins.",
            They_conne: "Sadly, due to technology gaps within content or resource and funding within western countries there is NO large organized efforts to export African product rather than import western made product to Africa.",
            Sadly_now: "We are developing Nile Basket to close that gap, ultimately improve the trade imbalance one product at a time, and improve Africans life by creating venues for exporting and generate market within western economies.",
            We_want_to: "We want to do better, which is exactly why at Nile Basket we’re doing things differently.",
            // Openbook_will_have: "We're designing a social network with humanity's best qualities such as kindness, compassion, tolerance, charity, empathy and cooperation at its core",
            honest_pe: "A marketplace for a better tomorrow, better Africa.",
            How_will_w: "Higher Good: Improve African’s life by improving trade imbalance",
            all_of_our_code: "The mission of Nile basket is improve",
            open_sourc: "trade imbalance.",
            this_means_: "by enabling Africans export one product at a time.   All of the products marketed at Nile Basket sourced within the continent, and made by African.  Therefore producing the product within Africa will enable employment as well higher revenue from western country sales.",
            additionally: "We also believe in a better African. That's why we'll be giving <strong>a percentage of our profits towards making Africa a better plac</strong>, now and forever in partnership with <a href=\"https://founderspledge.com/\" rel=\"noreferrer noopener\">FoundersPledge</a>.",
            Were_all_: "Because we're all different and that's something to embrace and celebrate. There is high number of African population within western countries, there is opportunity to supply that market with African products.",
            On_Openboo: "Today, there is no convenient method to access African products within one market place.  While there are marketplaces that provide some of the solution it is scattered across many sites and are all purely profit driven with minimal consideration for giving back.",
            And_if_the: "Similarly there are a lot of local metro area brick and motor shops, again accessibility is a gap, the product choice is minimal and products are overpriced due to multiple hand exchanges.",

            // Added lines
            Plan_to_compete: "Nile Basket plan to compete by studying all products available online or within store and directly source to enable the supplier higher revenue while enabling the buyer access directly through Nile Basket.",
            Plan_to_charge: "Nile Basket plan to charge reasonable Ad and commission fee to sustain and grow business for long time coming.",
            
            this_is_what_drove: "This is what initially drove us to build Openbook. The privacy and security of our users will always be at the core of everything we do. We don’t track anything you do, neither monetize your information nor share it without your explicit and informed consent.",
            How_does_t: "How does this translate to a real-life scenario?",
            Example: "Example",
            This_is_wh: "This is what data sharing normally looks like.",
            Lets_say_: "Let’s say you want to connect to a public WiFi hotspot , traditionally a window like the one below appears in your computer with the following:",
            The_box_be: "The box below appears on your screen.",
            Go_ahead: "(Go ahead, click around as you would normally)",
            Before_you: "Before you can enjoy of our free Wifi Hotspots you must continue with EvilCorp.",
            Continue_w: "Continue with EvilCorp",
            Congrats: "Congrats! You just shared your personal information with a WiFi hotspot!",
            Do_you_kno: "Do you know what data you just shared with the application?",
            If_you_hov: "If you hovered over the “i” icon for example, you may have noticed it says:",
            your_public_profile: "\"Your public profile includes name, profile picture, age range, gender, language, country and",
            other_pub: "\"other public info\"?",
            what_qualifies: "What qualifies as",
            other_public_info: "\"other public info\"?",
            and_why_wifi: "And why would a WiFi hotspot want all this information from you? Will it serve you faster WiFi based on your gender or your age? And what’s going to happen with your data once they have it?",
            People_sha: "People share more of their information that they’re initially aware of and once the data has been shared, there’s no going back.",
            Heres_how: "Here’s how we’re doing it:",
            Lets_say_2: "Let's say now you downloaded the awesome music app everyone has been recommending to you. As soon as you open the app, the box below shows up.",
            In_order_t: "In order to use Songipy you must continue with Openbook.",
            continue_with_ob: "Continue with <strong>Openbook</strong>",
            Did_you_sp: "Did you spot the differences?",
            Here_are_t: "Here are the most important ones.",
            explicit_n_informd: "Explicit and informed consent",
            We_make_su: "We make sure its always crystal clear what is being shared, so no pop-ups or vague descriptions. You can also inspect it yourself, just to be sure.",
            Safe_appli: "Safe applications",
            All_applic: "All applications will be reviewed by us. We will make sure they:",
            only_request_the: "only request the <strong>needed</strong> information",
            needed: "needed",
            exact: "exact",
            at_all_tim: "at all times",
            all: "all",
            emergency_: "emergency plan",
            notify_us: "notify us/you",
            have_the_exact: "have the <strong>exact</strong> location of your data available for you, <strong>at all times</strong>",
            delete_all_your: "delete <strong>all</strong> your information if you’d revoke the permission",
            have_an_emergency: "have an <strong>emergency plan</strong> in case of a data leak, and will <strong>notify us/you</strong> immediately if that were to happen",
            Once_they_: "Once they meet these strict guidelines they will have a badge below their logo. On your personal dashboard you can pinpoint the exact locations of your shared data, revoke application’s access and best of all, request deletion.",
            All_option: "All optional data sharing will be disabled by default, and it is up to you if you want to give that application more of your data instead of the other way around.",
            We_will_co: "We will continuously research and develop new ways to improve your privacy and security.",
            Some_examp: "Some examples of the technology we'll be researching and developing:",
            Cryptograp: "Cryptographically enforced data sharing policies",
            End_to_end: "End to end encryption, even on the browser",
            Public_key: "Public key cryptography on the browser",
            Post_quant: "Post-quantum cryptography algorithms and protocols",
            Generally_: "Generally speaking, the less we know about you and the more you know about us, the better.",
            Youve_pro: "You’ve probably put a tremendous amount of time (and data) into your existing social networks already. We aim to make the transition as smooth as possible.",
            migrating_to_ob: "That's why we'll be building tools to import your data from existing social networks.<sup>1</sup>. Think no more than 3 steps.",
            Lets_not_: "While the intial drive for Openbook was building a privacy-friendly social network, the complications of existing social networks span to numerous other subjects. Fear of missing out, digital social status, discrimination, digital mobbing, censorship and more.",
            Research: "There's a lot of research on the subjects, unfortunately until now only a few players had the power to do something with it. Not anymore.",
            Research_Embrace: "We're designing the platform with existing research in mind and experimenting with radical new concepts to tackle some of the previously mentioned issues.",
            Research_Solution: "We can't promise our solutions will work, we can however promise that aided by research and open-dialogue, we'll keep trying until they do.",
            _Our_rev: "6. Clean revenue.",
            Our_busine: "Our business model is not and will never be advertisement. Ever.",
            We_will_ha: "We will have a transparent revenue model based on a generic way for people to securely transact physical and digital goods and services inside the network. This will be done through an atomic digital unit of value. Although this initially reflected as a marketplace, our ambitions go way beyond that.",
            instead_our_revenue: "Our revenue model is an optional subscription called <span class=\"has-text-circular\"><strong>Open</strong>book Gold</span> offering access to more reactions, themes and customization options.",
            for_every_transaction: "In the future, we\'ll also introduce a digital currency used to support content creators, subscribe to publishers, buy and sell goods and services and more.",
            you_can_read_it: "You can read all about this currency in <a class=\"has-text-underlined\" href=\"https://medium.com/openbook-org/so-how-will-openbook-make-money-daf99589643d\" rel=\"noreferrer noopener\" target=\"_blank\">this article (6 mins approx. reading time)</a>",
            Apart_from: "Apart from this, we’re also planning to help enterprise customers setting up their own internal, self-hosted and secure social networks with extra functionality such as projects, identity and access management.",
            _With_t: "[1] With the new GDPR regulation, social networks must allow it’s users to do so now.",
            Still_have: "Still have questions?",
            Visit_our_: "Visit our FAQ",
            WiFi_Hotsp: "WiFi Hotspots App",
            wifi_hotspots_app: "<strong>WiFi Hotspots App</strong> will receive:",
            your_: "your ",
            public_pro: "public profile",
            friend_lis: "friend list",
            birthday_: "birthday",
            likes_: "likes",
            and_: "and ",
            email_addr: "email address",
            Edit_this: "Edit this",
            Continue: "Continue",
            Cancel: "Cancel",
            Back: "Back",
            _required_: "(required)",
            John_Doe: "John Doe, profile picture, 33+ years old, male and other public info",
            Mike_Chowd: "Mike Chowder, Barbara Lamb and 590 others.",
            January_1: "January, 12, 1990",
            The_Daily_: "The Daily Show, NBC and 978 others",
            john_doe_c: "john@doe.com",
            Success: "Success",
            The_social: "The social network has now shared your information with the application.",
            Restart_ex: "Restart example",
            _This_app: "  This application is safe to use.",
            It_has_met: "It has met our strict privacy and security policies.",
            Learn_more: "Learn more",
            Songipy: "Songipy",
            in_order_to_songipy: "In order to continue, <strong>Songipy</strong> requires your",
            name_: "name ",
            Optionally: "Optionally",
            you_would_like: ", you would like to share",
            your: "your",
            it_would_also: ", it would also like to access",
            profile_pi: "profile picture, ",
            age_range: "age range, ",
            gender: "gender,",
            language: "language, ",
            country_of: "country of birth, ",
            friends: "friends,",
            birthday: "birthday, ",
            and_likes: "and likes",
            Edit_share: "Edit shared optional data",
            Inspect_sh: "Inspect shared data",
            Openbook_h: "Openbook has now shared your information with Songipy.",
            Restart_pr: "Restart prototype",
            open_list: "Open list",
            open_image: "Open image",
            Jules_Winn: "Jules Winnfield",
            email: "email: ",
            male: "male",
            locale: "en_US",
            country_name: 'US',
            badmf_pfic: "badmf@pfiction.com",
            in_the_prototype: '<div class="content">\n ' +
                '                       <p>In the non-prototype version, this will redirect you to a page with detailed information on secure applications.</p>\n' +
                '                        <p>Brifly put, secure applications are applications that don\'t abuse the data sharing system, allow the deletion of your data from their systems and know at all times where your data is.</p>\n' +
                '                        <p>More information about this follows below.</p></div>',
            got_it: 'Got it!',
            in_non_prototype: "In the non-prototype version this would open a list with the full list of your {0}"
        }
    }
};


