<template>
    <figure class="image">
        <img class="honest-gif" :src="image" >
    </figure>
</template>

<style lang="scss">
    .honest-gif{
        height: auto !important;
        width: auto !important;
    }
</style>


<script>
    import image from './skating-psd.png';

    export default {
        name: 'ob-heart',
        data() {
            return {
                image: image
            }
        }
    }
</script>
