<template>
    <router-view></router-view>
</template>

<style lang="scss">

</style>

<script>
    export default {
        name: 'root'
    }
</script>
