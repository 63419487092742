<template>
    <figure class="image">
        <img class="dancing-gif" :src="dancingGif">
    </figure>
</template>

<style lang="scss">
    .dancing-gif{
        height: 230px !important;
        width: auto !important;
    }
</style>


<script>
    import dancingGif from './hi-five-L0.png';
    export default {
        name: 'ob-dancing-cactus',
        data(){
            return {
                dancingGif
            }
        }
    }
</script>
