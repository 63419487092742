<template>
    <figure class="image">
        <img class="security-icon" :src="secureImage">
    </figure>
</template>

<style lang="scss">
    .quest-gif{
        height: 230px !important;
        width: auto !important;
    }
</style>


<script>
    import secureImage from './river-flowing-psd.png';
    export default {
        name: 'ob-secure-icon',
        data(){
            return {
                secureImage
            }
        }
    }
</script>
