<template>
    <div>
        <div class="columns is-centered featured-in">
            <div class="column">
                <a href="https://techcrunch.com/2018/08/11/openbook-is-the-latest-dream-of-a-digital-life-beyond-facebook/"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image">
                        <img class="is-grayscale" src="./assets/tc-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.telegraph.co.uk/technology/2018/07/16/privacy-activists-launch-tracking-free-rival-facebook/"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image">
                        <img class="is-grayscale" src="./assets/telegraph-logo.svg">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.sueddeutsche.de/digital/openbook-1.4060625"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image">
                        <img class="is-grayscale" src="./assets/sd-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.cbronline.com/feature/openbook-opensource-socialmedia"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img position-top">
                        <img class="is-grayscale" src="./assets/cbr-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://uk.news.yahoo.com/privacy-activists-launch-tracking-free-110409830.html?guccounter=1"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/yahoo-logo.svg">
                    </figure>
                </a>
            </div>
        </div>
        <div class="columns is-centered featured-in">
            <div class="column">
                <a href="https://www.neowin.net/news/pgp-creator-backs-openbook-a-kickstarter-privacy-first-alternative-to-facebook"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/neowin.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://tecnoblog.net/252011/openbook-alternativa-facebook/" target="_blank"
                   class="featured-in__logo-wrapper"
                   rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/tecnoblog.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.presse-citron.net/openbook-un-anti-facebook-open-source-et-sans-pistage-des-utilisateurs/"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image">
                        <img class="is-grayscale" src="./assets/presse-citron-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.wizekat.eu/2018/07/openbook-un-anti-facebook-open-source-et-sans-pistage-des-utilisateurs/"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/wizekat-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.computeridee.nl/"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/computer_idee-logo.png">
                    </figure>
                </a>
            </div>
        </div>
        <div class="columns is-centered featured-in featured-in__small">
            <div class="column">
                <a href="http://hu.ign.com/openbook/39910/news/reklammentes-konkurenciat-kaphat-a-facebook"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/ign-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://siliconcanals.nl/news/dutch-startup-launches-facebook-rival-openbook-5-ways-it-can-be-a-big-threat/"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image">
                        <img class="is-grayscale" src="./assets/silicon.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://tweakers.net/nieuws/141045/facebook-alternatief-openbook-richt-zich-op-beschermen-privacy-zonder-tracking.html"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/tweakers-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.theinquirer.net/inquirer/news/3035956/openbook-is-like-facebook-but-without-all-the-digital-stealing"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/inquirer-logo.png">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.lesechos.fr/tech-medias/hightech/0301984319323-openbook-le-nouveau-reseau-social-qui-veut-proteger-la-vie-privee-des-utilisateurs-2192484.php"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/logo-lesechos-logo.svg">
                    </figure>
                </a>
            </div>
            <div class="column">
                <a href="https://www.pcprofessionale.it/news/openbook-nuova-alternativa-free-facebook/?cookie-policy-accepted"
                   class="featured-in__logo-wrapper"
                   target="_blank" rel="noopener noreferrer">
                    <figure class="image featured-in-img">
                        <img class="is-grayscale" src="./assets/pc-professionale-logo.png">
                    </figure>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .featured-in {

        .column {
            display: flex;
            justify-content: center;
            min-width: 17%;

            @media screen and (max-width: 769px) {
                min-height: 6em;
            }
        }

        .is-grayscale {

            &:hover {
                filter: none;
            }
        }

        &__small {
            .image {
                max-width: 10em;
            }
        }
    }

    .featured-in__logo-wrapper {
        display: flex;
        align-items: center;
    }

    .featured-in-img {
        max-width: 128px;
        height: auto;

        &.position-top {
            top: -1em;
        }
    }

    .featured-in-img-2 {
        max-width: 200px;
        height: auto;

        &.position-top {
            top: -1em;
        }
    }

    .featured-in-img-3 {
        max-width: 110px;
        height: auto;

        &.position-top {
            top: -1em;
        }
    }
</style>


<script>
    export default {
        name: 'ob-featured-on'
    }
</script>
